<template>
    <div class="wrapper">
        <div id="dialogue">
            <div class="InforList">
                <!-- <form class="search ng-pristine ng-valid">
                    <input
                        type="text"
                        placeholder="回车搜索..."
                    >
                </form> -->
                <nav>
                    <a
                        class="infor"
                        :class="{active:tabIndex==0}"
                        @click="changeTab(0)"
                    >
                        <i class="iconfont iconxiaoxi"></i>
                    </a>
                    <a
                        class="staff"
                        :class="{active:tabIndex==1}"
                        @click="changeTab(1)"
                    >
                        <i class="iconfont iconkehu1"></i>
                    </a>
                    <a class="Launch">
                        <i class="iconfont iconno-select-add" @click="dropdownClick('createTeam')"></i>
                    </a>
                </nav>
                <div class="List">
                    <div
                        id="infor"
                        :class="{active:tabIndex==0}"
                    >
                        <!-- 最近会话 -->
                        <session @clickSession="enterChat"></session>
                    </div>
                    <div
                        id="staff"
                        :class="{active:tabIndex==1}"
                    >
                        <!-- 联系人 -->
                        <contacts
                            @clickContact="enterChat"
                            :contacts-list="contactsListData"
                        ></contacts>
                    </div>
                    <div id="Launch"></div>
                </div>
            </div>
            <!-- 聊天窗口 -->
            <chat
                :is-show="show"
                :session="sessionData"
                :chatdata="chatData"
            ></chat>
            <div
                v-if="!show"
                class="chat-default"
            ></div>
        </div>
        <choseMembers
            :show-dialogue="showDialogue"
            :dialogue-tit="'选取联系人'"
            @update-show="updateShowDialogue"
            @sure-members="sureMembers"
        ></choseMembers>
        <teamChoseMembers
            :show-dialogue="showTeamChoseDialogue"
            :dialogue-tit="'创建群组'"
            @update-show="updateShowDialogue"
            :type="'createTeam'"
            @sure-members="sureMembers"
        ></teamChoseMembers>
    </div>
</template>

<script>
import session from './components/dialogue/TeamSession';
import contacts from './components/dialogue/TeamContacts';
import chat from './components/dialogue/Chat';
import choseMembers from './components/choseMembers';
import teamChoseMembers from './components/teamChoseMembers';
import jwtDecode from 'jwt-decode';
export default {
    components: {
        session,
        contacts,
        chat,
        choseMembers,
        teamChoseMembers,
    },
    data() {
        return {
            show: false,
            sessionData: '',
            chatData: {},
            tabIndex: 0,
            showDialogue: false, // 显示创建群组弹框
            data: [],
            defaultProps: {
                children: 'children',
                label: 'label',
            },
            type: '',
            contactsListData: [],
            dialogueTit: '',
            showTeamChoseDialogue: false,
        };
    },
    methods: {
        enterChat(session, rowData) {
            this.show = true;
            this.sessionData = session;
            this.chatData = rowData;
        },
        changeMenu(item, index) {
            this.selectedSlide = index;

        },
        changeTab(tabIndex) {
            this.tabIndex = tabIndex;
        },
        updateShowDialogue(val) {
            this.showDialogue = val;
            this.showTeamChoseDialogue = val;
        },
        // 选取人员完成后回调
        sureMembers(data) {
            if (data.data.length < 1) {
                this.$message.warning('未选择成员');
                return;
            }
            if (data.type === 'addMembers') {
                const rows = data.data.map(v => {
                    window.$globalHub.$store.dispatch('addFriend', v.accid);
                    return {
                        friend_id: v.user_id,
                        friend_name: v.user_name,
                        friend_org_id: v.org_id,
                        friend_org_name: v.org_name,
                    };
                });
                this.betchAddFriend(rows);
            } else if (data.type === 'createTeam') {
                // 调用云信sdk-创建群组
                const accounts = data.data.map(friend => {
                    return friend.accid;
                });
                const friendslist = window.$globalHub.$store.state.friendslist;
                accounts.map(item => {
                    const arr = friendslist.filter(v => v.account === item);
                    if (arr.length <= 0) {
                        window.$globalHub.$store.dispatch('addFriend', item);
                    }

                });
                const names = data.data.map(friend => {
                    return friend.user_name;
                });
                // const owner = sessionStorage.getItem('RealName');
                const token = window.sessionStorage.getItem('token');
                // 解析token，获取token中数据
                const decode = jwtDecode(token);
                const owner = decode.Name;
                const name = [owner].concat(names).join('、').slice(0, 20) + '等人';
                const type = 'normal';
                window.$globalHub.$store.dispatch('delegateTeamFunction', {
                    functionName: 'createTeam',
                    options: {
                        type: type,
                        name: name,
                        avatar: '',
                        accounts: accounts,
                        done: error => {
                            if (error) {
                                this.$message.error(error);
                            }
                            if (!error) {
                                this.$message.success('创建成功！');
                            }
                        },
                    },
                });
            }
        },
        // 下拉 添加联系人、创建群组
        dropdownClick(command) {
            if (command === 'addMembers') {
                this.showDialogue = true;
            } else {
                this.showTeamChoseDialogue = true;
            }
        },
        // 添加联系人接口
        betchAddFriend(data) {
            this.$axios
                .post('/interfaceApi/message/MessageNetease/betch_add_user_friend', data)
                .then(res => {
                    this.$message.success(res);
                    this.getFriends();
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
        // 获取联系人
        getFriends() {
            this.$axios
                .get('/interfaceApi/message/MessageNetease/get_friends')
                .then(res => {
                    const newArr = res.map(item => {
                        item.friend_acc_id = 'p2p-' + item.friend_acc_id;
                        return item;
                    });
                    this.contactsListData = newArr;
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
    },
    created() {
        this.getFriends();
    },
};
</script>
<style>
.wrapper {
    width: 100%;
    height: 100%;
}
.cdialog .el-dialog__body {
    height: calc(100% - 54px);
}
/* .el-dialog__body>div{
    height: 100%;
} */
</style>

